document.addEventListener('DOMContentLoaded',(e)=>{

    let elMenu =  document.querySelector('header .menu');
    let elBasket =  document.querySelector('header .basket');
    let elHeader =  document.querySelector('header');

    if(!elHeader.dataset.opened){
        elHeader.dataset.opened = '';
        }



    // Burger
    let arSections   = document.querySelectorAll('header .menu_section');
    let handleBurger = (e) => {
        console.log('handleBurger');
        let header = e.target.closest('header');
        header.classList.toggle('menu_active');
        header.dataset.opened = (header.dataset.opened == '') ? 'menu':'';
        if(!header.classList.contains('menu_active') || header.dataset.opened == ''){
            arSections.forEach( el => el.classList.remove('--opened') );
        }
    }
    let arBurgers = document.querySelectorAll('.menu_toggler');
    arBurgers.forEach( el => el.addEventListener('click',handleBurger) );
    
    if(elMenu){

        let arNextArrows = elMenu.querySelectorAll('.menu_next');
        let arPrevArrows = elMenu.querySelectorAll('.menu_prev');
        

        // Prev Next
    
        let handleNextArrow = (e) => {
            console.log('handleNextArrow');
            e.preventDefault();
            let el = e.target;
            let elSection = el.closest('.menu_section');
            // let arSections = elSection.parentElement.querySelectorAll('.menu_section');
            arSections.forEach( el => el.classList.remove('--opened') );
            elSection.classList.add('--opened');
        }
        let handlePrevArrow = (e) => {
            console.log('handlePrevArrow');
            e.preventDefault();
            // let el = e.target;
            let el = elMenu.querySelector('.menu_section.--opened .menu_subsection');
            let elSection = el.closest('.menu_section');
            elSection.classList.remove('--opened');
        }

        arNextArrows.forEach( el => el.addEventListener('click',handleNextArrow) );
        arPrevArrows.forEach( el => el.addEventListener('click',handlePrevArrow) );

        // swipes
        let xDown = null;
        let lDown = null;
        let yDown = null;
        let elSub = null;
        let xDiff = 0;
        let handleTouchStart = (evt) => {
            const firstTouch = evt.touches[0];// getTouches(evt)[0];
            xDown = firstTouch.clientX;
            yDown = firstTouch.clientY;
            elSub = elMenu.querySelector('.menu_section.--opened .menu_subsection');
            if(elSub){
                elSub.classList.add('--touched');
                let coords = elSub.getBoundingClientRect();
                lDown = coords.left;
            }else{
                elMenu.classList.add('--touched');
                let coords = elMenu.getBoundingClientRect();
                lDown = coords.left;
            }
        };
        let handleTouchMove = (evt) => {
            if ( ! xDown || ! yDown) {
                return;
            }
            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;
            xDiff = xDown - xUp;
            var yDiff = yDown - yUp;
            if(elSub){
                if(xDiff <0){
                    let lNew = lDown-xDiff;
                    if(lNew < 0) return;
                    elSub.style.setProperty('left',`${lNew}px`);
                }
            }else{
                
                // console.log('xDiff', xDiff);
                if(xDiff > 0){
                    let lNew = lDown-xDiff;
                    if(lNew > 0) return;
                    elMenu.style.setProperty('left',`${lNew}px`);
                }            
            }
        }
        let handleTouchEnd = (evt) => {
            if(elSub){
                let coords = elSub.getBoundingClientRect();
                if(coords.left > 0) handlePrevArrow(evt);
                elSub.classList.remove('--touched');
                elSub.style.removeProperty('left');    
            }else{
                let coords = elMenu.getBoundingClientRect();
                if(xDiff > 15) handleBurger(evt);
                elMenu.classList.remove('--touched');
                elMenu.style.removeProperty('left');
            }
            elSub = null;
            xDown = null;
            yDown = null;
            xDiff = 0;
        }
        elMenu.addEventListener('touchstart', handleTouchStart, false);
        elMenu.addEventListener('touchmove', handleTouchMove, false);
        elMenu.addEventListener('touchend', handleTouchEnd, false);
    }

    if(elBasket){
        // swipes
        let xDown = null;
        let lDown = null;
        let yDown = null;
        let xDiff = 0;

        let handleTouchStart = (evt) => {
            const firstTouch = evt.touches[0];// getTouches(evt)[0];
            xDown = firstTouch.clientX;
            yDown = firstTouch.clientY;
            let coords = elBasket.getBoundingClientRect();
                lDown = coords.left;
            elBasket.classList.add('--touched');
            // console.log(coords)
        }
        let handleTouchMove = (evt) => {
            if ( ! xDown || ! yDown) {
                return;
            }

            var xUp = evt.touches[0].clientX;
            var yUp = evt.touches[0].clientY;
            xDiff = xDown - xUp;

            let lNew = lDown-xDiff;
            if(lNew < 0) return;
            if(xDiff > 0) return;

            // elBasket.style.setProperty('left',`${lNew}px`);
            elBasket.style.setProperty('transform',`translateX(${lNew}px)`);
        }

        let handleTouchEnd =  (evt) => {
            if(xDiff < -15){
                console.log()
                handleBurger(evt);
            }
            elBasket.classList.remove('--touched');
            // elBasket.style.removeProperty('left');
            elBasket.style.removeProperty('transform');

            xDiff = 0;
            xDown = null;
            yDown = null;
        }

        elBasket.addEventListener('touchstart', handleTouchStart, false);
        elBasket.addEventListener('touchmove', handleTouchMove, false);
        elBasket.addEventListener('touchend', handleTouchEnd, false);
    }

    // sticky

    let stickyScrollHandler = (e) => {
        // console.log(e)
        let y = window.scrollY;
        if(y > 0 && elHeader.dataset.sticked != 1){
            elHeader.dataset.sticked = 1;
        }
        if(y == 0 && elHeader.dataset.sticked == 1){
            elHeader.dataset.sticked = 0;
        }
    }
    window.addEventListener('scroll',stickyScrollHandler);
    window.addEventListener('load',stickyScrollHandler);

    // openers

    let arOpeners = document.querySelectorAll('.header_open');
    arOpeners.forEach(el => el.addEventListener('click', (e)=>{
        let el = e.currentTarget;
        // console.log()
        let key = el.dataset.open;
        elHeader.dataset.opened = key;
        //
        if(key == 'search'){
            let elInp = document.querySelector('#topsearch_input');
            if(elInp) elInp.focus();
        }
    }));



});