document.addEventListener('DOMContentLoaded',(e)=>{
    let handleNumberInput = (e) => {
        let val = String(e.target.value).replace(/\D/g,'') * 1;
        e.target.value = val;
    }
    document.querySelectorAll('.quant').forEach( elQuantRoot => {
        let elQuantInp = elQuantRoot.querySelector('input');
        if(elQuantInp){
            let handleQuantIncrement = (e) => {
                let el = e.target;
                let [val,min,max] = [+elQuantInp.value, +elQuantInp.min,+elQuantInp.max];
                let delta = +el.dataset.d;
                val+=delta;
                if(val < min) return;
                if(max > min && val > max) return;
                elQuantInp.value = val;
            }
            elQuantInp.addEventListener('input',handleNumberInput);
            elQuantRoot.querySelectorAll('.quant_trigger').forEach( el => el.addEventListener('click',handleQuantIncrement));
        }
    });
});