// require('./own-carousel.js');
document.addEventListener('DOMContentLoaded',(e)=>{
    let elHeader =  document.querySelector('header');
    document.querySelectorAll(".own-carousel__container").forEach(elOCC => {
        let iPR = Number(elOCC.dataset.ipr ?? 1);
        let iW = 100 / iPR;
        let responsive = {}
        let rs = {iprXs: 375, iprSm: 768, iprMd: 1024, iprLg: 1200, iprXl: 1920};
        for(let [key, wid] of Object.entries(rs)){
            // console.log(key, wid, elOCC.dataset[key])
            if(elOCC.dataset[key]){
                responsive[wid] = [
                    Number(elOCC.dataset[key]),
                    100 / elOCC.dataset[key]
                ];
            }
        }
        // if(elOCC.dataset.iprSm) responsive[768] = Number(elOCC.dataset.iprSm);
        // console.log(elOCC.dataset, responsive)
        // elOCC.ownCarousel({
        //     itemPerRow: iPR,
        //     itemWidth: iW, 
        //     responsive: responsive,
        // });
        
        let slider = new OwnCarousel(elOCC, {
                itemPerRow: iPR,
                itemWidth: iW, 
                responsive: responsive,
                autoplay: (elOCC.dataset.autoplay ?? 0) * 1,
                transitionDuration: (elOCC.dataset.transitionDuration ?? 250) * 1,
            });
        elOCC.addEventListener('changed', e => {
            let el = elOCC;
            // console.log(elOCC, slider.index)
            if( el.matches('.guru_slider .own-carousel__container') ){
                let slideActive = el.querySelectorAll('.own-carousel__item')[slider.index];
                // console.log(el, el.matches('.guru_slider .own-carousel__container'), slideActive);
                if(slideActive.dataset.color){
                    elHeader.dataset.dark = (slideActive.dataset.color != 'dark') ? '1' : '0';
                }
            }
        });
        elOCC.dispatchEvent(new CustomEvent('changed'));
    });
    // let elOC = document.querySelector(".own-carousel__container");
    // if(elOC){
    // }
    // document.querySelectorAll('.own-carousel__container').forEach( el => el.addEventListener('changed', e => {
    //     let el = e.target;
    //     if( el.matches('.guru_slider .own-carousel__container') ){
    //         let slideActive = el.querySelectorAll('.own-carousel__item')[el.index];
    //         console.log(el, el.matches('.guru_slider .own-carousel__container'), slideActive);
    //         if(slideActive.dataset.color){
    //             elHeader.dataset.dark = (slideActive.dataset.color != 'dark') ? '1' : '0';
    //         }
    //     }
    // }))
});
