window.addEventListener('load',(e)=>{

    let node = document.querySelector('.ymap');
    if(node){
        let map = new ymaps.Map(node, {
            center: [55.833188,37.313169],
            zoom: 17,
            type: 'yandex#map',
            // adjustZoomOnTypeChange: true
        });

        let balloonContent = '<p><b>Красногорск, улица Народного Ополчения, 9</b></p>';
        balloonContent+='<p>Ежедневно с 10:00 до 22:00</p>';
        balloonContent+='<a class="btn" href="https://yandex.ru/maps/org/hairguru/236835284049/" target="_blank">Проложить маршрут</a>';

        let myPlacemark = new ymaps.Placemark([55.833188,37.313169],{
            balloonContent: balloonContent,
        },{
            iconLayout: 'default#image',
            iconImageHref: 'images/ymap_icon.png',
            iconImageSize: [68, 68],
            iconImageOffset: [-34, -34],
            iconContentOffset: [0, 0],
            iconContentLayout: '',
        });
        // console.log(map.)
        map.geoObjects
            .add(myPlacemark)
    }

});