document.addEventListener('DOMContentLoaded',(e)=>{
    let rangeMin = 100;
    const range = document.querySelector(".range-selected");
    const rangeInput = document.querySelectorAll(".range-input input");
    const rangePrice = document.querySelectorAll(".range-price input");

    let eventInput = new Event('input');
    let eventChange = new Event('change');
    
    rangeInput.forEach((input) => {
        input.addEventListener("input", (e) => {
            let minRange = parseInt(rangeInput[0].value);
            let maxRange = parseInt(rangeInput[1].value);
            if (maxRange - minRange < rangeMin) {
                if (e.target.className === "min") {
                    rangeInput[0].value = maxRange - rangeMin;
                } else {
                    rangeInput[1].value = minRange + rangeMin;
                }
            } else {
            rangePrice[0].value = minRange;
            rangePrice[1].value = maxRange;
            rangePrice[0].dispatchEvent(eventChange);
            rangePrice[1].dispatchEvent(eventChange);
            let [min,max] = [rangeInput[0].min,rangeInput[0].max];
            let rangeValues = max - min;
            let rangeMinPercent = ((minRange-min) / rangeValues) * 100;
            let rangeMaxPercent = 100 - ((maxRange-min) / rangeValues) * 100;
            // console.log(rangeMaxPercent)
            range.style.left = rangeMinPercent + "%";
            // console.log('left',(minRange-min)/rangeValues,' * 100% = ',range.style.left,' of',rangeValues)
            range.style.right = rangeMaxPercent + "%";
            }
        });
        input.dispatchEvent(eventInput);
    });

    rangePrice.forEach((input) => {
        input.addEventListener("input", (e) => {
            let minPrice = String(rangePrice[0].value).replace(/\D/g,'') * 1;
            let maxPrice = String(rangePrice[1].value).replace(/\D/g,'') * 1;
            let [min,max] = [rangeInput[0].min,rangeInput[0].max];
            let rangeValues = max - min;

            if (maxPrice - minPrice >= rangeMin && maxPrice <= rangeInput[1].max) {
                if (e.target.classList.contains("min")) {
                    rangeInput[0].value = minPrice;
                    let rangeMinPercent = ((minPrice-min) / rangeValues) * 100;
                    range.style.left = rangeMinPercent + "%";
                } else {
                    rangeInput[1].value = maxPrice;
                    let rangeMaxPercent = 100 - ((maxPrice-min) / rangeValues) * 100;
                    range.style.right = rangeMaxPercent + "%";
                }
            }

            e.target.dispatchEvent(eventChange);


        });
        input.addEventListener("change", (e) => {
            // console.log(e)
            let el = e.target, val = el.value;
            val = String(val).replace(/\D/g,'');
            // console.log(val)
            let valFormatted = Intl.NumberFormat().format(val);
            el.value = valFormatted;
        });
        input.dispatchEvent(eventChange);
    });

    // rangePrice.forEach


});