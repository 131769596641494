function OwnCarousel(root, options){
    const {
        itemPerRow = 4,
        itemWidth = 24,
        loop = true,
        responsive = {},
        draggable = true,
        mouseWheel = false,
        autoplay = 0,
        transitionDuration = 250,
        stopAutoplayWhenHover = true,
        nav = true,
        isRTL = false,
    } = options;
    const eventChanged = new CustomEvent('changed');
    let currentPos = 0;
    let navItems = root.querySelectorAll(".own-carousel__nav .own-carousel__navitem");
    //extract arguments
    this.carousel = root.querySelector(".own-carousel");
    // console.log(this, this.carousel)
    this.carouselOuter = root.querySelector(".own-carousel__outer");
    this.itemWidthBig = this.itemWidth = itemWidth;
    this.itemPerRowBig = this.itemPerRow = itemPerRow;
    this.responsive = responsive;
    this.gapWidth = (100 - itemPerRow * itemWidth) / (itemPerRow - 1) || 0; // prevent divide 0

    root.style.setProperty("--width", `${itemWidth}%`);
    root.style.setProperty("--margin", `${this.gapWidth}%`);

    this.index = 0;
    this.timeoutId = 0;
    this.intervalId;
    this.carouselItem = this.carousel.children;
    this.imgWidth = this.carouselItem[0].getBoundingClientRect().width;
    this.numberOfItem = this.carouselItem.length;
    this.step =
        this.imgWidth + (this.gapWidth / this.itemWidth) * this.imgWidth; //calculate the step to translate
    this.stepToMoveAutoplay = isRTL ? -1 : 1;

    this.carousel.addEventListener('transitionend',(function(e){
        // console.log(this.index)
        if(this.index >= this.numberOfItem + itemPerRow){
            // console.log('bingo')
            this.index = this.index - this.numberOfItem;
            let ttd = -this.index * this.step;
            // console.log('this.index',this.index,'ttd',ttd)
            this.carousel.style.transition = "all 0s";
            this.carousel.style.transform = `translate3d(${ttd}px,0,0)`;
            // console.log(this.carousel)
        }
    }).bind(this));

    if (loop) {
        //if loop is true, clone carousel item
        this.index = itemPerRow;
        this.carousel.style.transform = `translate3d(${
            -this.index * this.step
        }px,0,0)`;
        // let listItemsPerRow = 
        let listItemsPerRow = [this.itemPerRow, ...Object.values(this.responsive).map(el => el[0])];
        let maxItemsPerRow = Math.max(...listItemsPerRow);
        console.log('loop',maxItemsPerRow)
        //this.itemPerRow
        for (let i = 0; i < maxItemsPerRow; i++) {
            let cloneNode = this.carouselItem[i].cloneNode(true);
            this.carousel.insertAdjacentElement("beforeend", cloneNode);
        }
        let count = 0;
        while (count != this.itemPerRow) {
            let cloneNode =
                this.carouselItem[this.numberOfItem - 1].cloneNode(true);
            this.carousel.insertAdjacentElement("afterbegin", cloneNode);
            count++;
        }
    }

    this.translateSlide = () => {
        // console.log('this.index',this.index,'ttd',-this.index * this.step)
        // debugger;
        this.carousel.style.transform = `translate3d(${
            -this.index * this.step
        }px,0,0)`;

        if(navItems.length > 0){
            navItems.forEach(el => el.classList.remove('active'));
            navItems.forEach((el,index) => (this.index-1 != index && !(this.index > this.numberOfItem && index == 0)) || (el.classList.add('active')));
            // console.log('this.index',this.index, this.numberOfItem)
        }
        //just a function used many time, to translate slide
        root.dispatchEvent(eventChanged);
    };

    this.resetSlide = (step) => {
        //reset slide, to make it loop
        if (this.index + step < 1) this.index = this.numberOfItem + 1;
        if (this.index + step >= this.numberOfItem + this.itemPerRow)
            this.index = this.itemPerRow - 1;
        this.carousel.style.transition = "none";
        this.translateSlide();
        //reset it silently by changing transition to none
        //then move it, i had delay 20ms because if we change inline style transition too fast, it will not work properly
        setTimeout(() => {
            this.carousel.style.transition = `all ${transitionDuration * 0.001}s`;
            this.index += step;
            this.translateSlide();
        }, 20);
    };

    this.moveSlide = (step) => {
        step *= 1;
        // console.log(typeof step, step)
        this.carousel.style.transition = `all ${transitionDuration * 0.001}s`;
        //every time we move slide, add transition
        if (loop) {
            if (
                this.index + step < 1 ||
                this.index + step >= this.numberOfItem + this.itemPerRow
            ) {
                this.resetSlide(step);
            } else {
                this.index += step;
                this.translateSlide();
            }
        } else {
            if (
                this.index + step < 0 ||
                this.index + step > this.numberOfItem - this.itemPerRow
            )
                return;
            else {
                this.index += step;
                this.translateSlide();
            }
        }
    };

    this.goTo = (slide) => {
        this.carousel.style.transition = `all ${transitionDuration * 0.001}s`;
        this.index = slide;
        this.translateSlide();
    }

    if (nav) {
        root.querySelectorAll(".control__prev").forEach(el => el.addEventListener("click", (() => {
            this.moveSlide(-1);
        }).bind(this)))
        // this.querySelector(".control__prev");
        root.querySelectorAll(".control__next").forEach(el => el.addEventListener("click", (() => {
            this.moveSlide(1);
        }).bind(this)));
        // this.querySelector(".control__next");
    }

    
    if(navItems.length > 0){
        navItems.forEach((navItem, i) => navItem.addEventListener('click',((i,e)=>{
            let navItem = e.currentTarget;
            this.goTo(i);
            navItems.forEach(el => el.classList.remove('active'));
            navItems.forEach((el,index) => (i-1 != index) || (el.classList.add('active')));
        }).bind(this,i+1)))
        navItems[0].classList.add('active');
    }


    if (draggable) {
        //if draggable is true, add draggable-support event, variables,...
        let firstPos = (currentPos = 0);

        let dragStartHandle = (e) => {
            this.carousel.style.transition = "none";
            if (e.type === "touchstart") {
                currentPos = e.touches[0].clientX;
                document.addEventListener("touchmove", dragHandle);
                document.addEventListener("touchend", dragEndHandle);
            } else {
                this.carouselOuter.style.cursor = "grab";
                currentPos = e.clientX;
                document.addEventListener("mousemove", dragHandle);
                document.addEventListener("mouseup", dragEndHandle);
            }
            firstPos = currentPos;
            //add necessary listener, style, reset first and current position
            if (autoplay) {
                clearInterval(this.intervalId);
                clearTimeout(this.timeoutId);
            }
        };

        let dragHandle = (e) => {
            let currentMove = parseFloat(
                this.carousel.style.transform.slice(12)
            ); //get the x-axis of transform3d
            let currentIndex = -currentMove / this.step;

            let x = e.type === "touchmove" ? e.touches[0].clientX : e.clientX; //get current coordinate
            let distanceMoved = x - currentPos;
            if (loop) {
                if (currentIndex <= 0) {
                    this.index = this.numberOfItem;
                    this.translateSlide();
                    // this.goTo(this.numberOfItem);
                } else if (
                    currentIndex >=
                    this.numberOfItem + this.itemPerRow
                ) {
                    // this.index = this.itemPerRow;
                    // this.translateSlide();
                    this.goTo(this.itemPerRow);
                } else
                    this.carousel.style.transform = `translate3d(${
                        currentMove + distanceMoved
                    }px,0,0)`;
            } else {
                if (
                    currentIndex < 0 ||
                    currentIndex > this.numberOfItem - this.itemPerRow
                )
                    this.carousel.style.transform = `translate3d(${
                        currentMove + distanceMoved / 5
                    }px,0,0)`;
                //when user drag out of bound, decrease speed
                else
                    this.carousel.style.transform = `translate3d(${
                        currentMove + distanceMoved
                    }px,0,0)`;
            }
            currentPos = x;
        };

        this.checkIndex = (currentMove) => {
            let temp = currentMove;
            while (temp >= this.step) {
                temp -= this.step;
            }
            if (
                (temp > 50 && firstPos - currentPos >= 0) ||
                (this.step - temp < 50 && firstPos - currentPos <= 0)
            )
                return Math.ceil(currentMove / this.step);
            return Math.floor(currentMove / this.step);
        }; // this function is used to check current index to determine move next or previous

        let dragEndHandle = (e) => {
            if (e.type === "touchend") {
                document.removeEventListener("touchmove", dragHandle);
                document.removeEventListener("touchend", dragEndHandle);
            } else {
                document.removeEventListener("mousemove", dragHandle);
                document.removeEventListener("mouseup", dragEndHandle);
                this.carouselOuter.style.cursor = "auto";
            }
            //remove unnecessary event listener and style
            let currentMove = parseFloat(
                this.carousel.style.transform.slice(12)
            );
            this.index = this.checkIndex(-currentMove);
            if (!loop) {
                if (this.index > this.numberOfItem - this.itemPerRow)
                    this.index = this.numberOfItem - this.itemPerRow;
                if (this.index < 0) this.index = 0;
            }
            this.carousel.style.transition = `all ${transitionDuration * 0.001}s`;
            this.translateSlide();
            if (autoplay) {
                clearInterval(this.intervalId);
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => {
                    this.intervalId = setInterval(
                        this.moveSlide,
                        autoplay,
                        this.stepToMoveAutoplay
                    );
                }, 2000);
            }
        };

        // this.carouselOuter.addEventListener("mousedown", dragStartHandle);
        this.carouselOuter.addEventListener("touchstart", dragStartHandle);
        // i had to create carouselOuter because carousel will be hidden when slide is working
    }

    if (mouseWheel) {
        this.carouselOuter.addEventListener("wheel", (e) => {
            e.preventDefault();
            if (e.deltaY > 0) this.moveSlide(-1);
            else this.moveSlide(1);
        });
    }

    if (autoplay) {
        this.timeoutId = setTimeout(() => {
            this.intervalId = setInterval(
                this.moveSlide,
                autoplay,
                this.stepToMoveAutoplay
            );
        }, 3000);
        if (stopAutoplayWhenHover) {
            this.carouselOuter.addEventListener("mouseenter", () => {
                clearTimeout(this.timeoutId);
                clearInterval(this.intervalId);
            });
            this.carouselOuter.addEventListener("mouseleave", () => {
                clearInterval(this.intervalId);
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => {
                    this.intervalId = setInterval(
                        this.moveSlide,
                        autoplay,
                        this.stepToMoveAutoplay
                    );
                }, 2000);
            });
        }
    }

    this.responsiveHandler = function(){
        let windowWidth = window.innerWidth;
        let flag = false;
        let crsContainer = root;

        for (let property in this.responsive) {
            if (windowWidth >= property) {
                this.itemPerRow = this.responsive[property][0];
                this.itemWidth = this.responsive[property][1];
                flag = true;
                // break;
            }
        }
        if (!flag) {
            //all property are smaller the window width, happen when we increase window width, reset these property to highest value
            this.itemPerRow = this.itemPerRowBig;
            this.itemWidth = this.itemWidthBig;
        }

        this.gapWidth = (100 - this.itemPerRow * this.itemWidth) / (this.itemPerRow - 1) || 0;
        console.log(this)
        root.style.setProperty("--width", `${this.itemWidth}%`);
        root.style.setProperty("--margin", `${this.gapWidth}%`);
    
        //divide into 2 phase to avoid wrong calculating for imgWidth

        this.imgWidth = this.carouselItem[0].getBoundingClientRect().width;
        this.step = this.imgWidth + (this.gapWidth / this.itemWidth) * this.imgWidth;
        //change important property for responsive
        this.moveSlide(0);
        //fit carousel in correct position
    }

    window.addEventListener("resize", debounce(this.responsiveHandler.bind(this), 500));
    // debounce(this.responsiveHandler.bind(this), 500)
    this.responsiveHandler();

}

function debounce(fn, delay) {
    let id = null;
    return function (args) {
        clearTimeout(id);
        id = null;
        id = setTimeout(function () {
            fn.call(this, args);
        }, delay);
    };
}
