document.addEventListener('DOMContentLoaded',(e)=>{

    /*!
    * jQuery Brazzers Carousel v1.0.0 (http://webdesign-master.ru)
    * Copyright 2015 WebDesign Master.
    * Thanks guys
    */

    let arRoots = document.querySelectorAll('.brazzers');
    arRoots.forEach( elRoot => {
        
        elRoot.classList.add("brazzers-daddy");
        let eventHover = new Event('mouseover');
        
        let tmbWrap = document.createElement('div');
            tmbWrap.className='tmb-wrap';
        let tmbWrapTable = document.createElement('div');
            tmbWrapTable.className='tmb-wrap-table';
            tmbWrap.append(tmbWrapTable);
        let arTmbs = [];
        elRoot.append(tmbWrap);

        let imgWrap = document.createElement('div');
            imgWrap.className='image-wrap';
        elRoot.append(imgWrap);

        let arImages = elRoot.querySelectorAll('img');

        let tmbHover = (index, e) => {
            arImages.forEach((el,i) => el.style.setProperty('display',(i != index)?'none':'block') );
            arTmbs.forEach((el,i) => el.className = (i != index)?'':'active');
        }

        arImages.forEach((elImg, i) => {
            imgWrap.append(elImg);
            let tmb = document.createElement('div');
                tmb.addEventListener('mouseover',tmbHover.bind(tmb,i));
                tmbWrapTable.append(tmb);
                arTmbs.push(tmb);
            if(i > 0){
                elImg.style.setProperty('display','none');
            }else{
                tmb.classList.add('active');
            }
        });

        tmbWrapTable.addEventListener('touchmove', (event) => {
            var myLocation = event.changedTouches[0];
			var realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
            if(realTarget.classList.contains('active')) return;
			event.preventDefault();
            realTarget.dispatchEvent(eventHover);
            // console.log(realTarget)
        });

    })
});