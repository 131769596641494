document.addEventListener('DOMContentLoaded',(e)=>{
    let elHeader =  document.querySelector('header');
    // popups
    // let ops = 
    document.querySelectorAll('[data-popup]').forEach(el => el.addEventListener('click', e => {
        let el = e.target, targetID = el.dataset.popup;
        let elTarget = document.getElementById(targetID);
        if(elTarget){
            e.preventDefault();
            // close others first
            elHeader.dataset.opened = '';
            document.querySelectorAll('.popup.opened').forEach( el => el.classList.remove('opened'));
            elTarget.classList.add('opened');
        }
    }));
    // console.log('ops',ops)
    document.querySelectorAll('.popup_close').forEach( el => el.addEventListener('click', e => {
        let el = e.target.closest('.popup');
        if(el){
            el.classList.remove('opened');
        }
    }));
    
});
window.openPopup = function(targetID){
    let elHeader =  document.querySelector('header');
    let elTarget = document.getElementById(targetID);
    if(elTarget){
        // e.preventDefault();
        // close others first
        elHeader.dataset.opened = '';
        document.querySelectorAll('.popup.opened').forEach( el => el.classList.remove('opened'));
        elTarget.classList.add('opened');
    }
}