document.addEventListener('DOMContentLoaded',(e)=>{

    let eventClick = new Event('click');
    // let that = 
    document.querySelectorAll(".dropdown-select .dropdown-select__title").forEach( el => el.addEventListener('click', function(e){
        let that = e.target.closest('.dropdown-select');
        let menu = that.querySelector('.dropdown-select__list');
        that.classList.contains("clicked") || (that.classList.add("clicked"));
        that.classList.toggle("opened");
        menu.style.setProperty('display',that.classList.contains("opened") ? 'block':'none')
    }));
    document.querySelectorAll(".dropdown-select__list a").forEach( el => el.addEventListener('click', function(e){
        let $select = e.target.closest(".dropdown-select");
        let $item = e.target.closest(".dropdown-select__list-item");
        $select.querySelectorAll('.dropdown-select__list-item').forEach(el=>el.classList.remove('active'));
        $item.classList.add('active')
        let text = e.target.innerText;
        $select.querySelector(".dropdown-select__title span").innerText = text;
        let elTitle = $select.querySelector(".dropdown-select__title");
        if(elTitle) elTitle.dispatchEvent(eventClick); 
        // console.log(text)

    }));

});