document.addEventListener('DOMContentLoaded',(e)=>{
   let arPlus = document.querySelectorAll('.spoiler_trigger') ;
   let handleSpoiler = (e) => {
        e.preventDefault();
        let elSubject = e.target.closest('.spoiler_root');
        let elSpoiled = elSubject.querySelector('.spoiler_body');
        
        if(elSubject && elSpoiled){
            if(elSubject.classList.contains('active')){
                elSubject.classList.remove('active');
                elSpoiled.style.setProperty('height',null);
            }else{
                if(elSubject.classList.contains('spoiler_single')){
                    // console.log(elSubject.parentElement.querySelectorAll('.spoiler_root.active'))
                    elSubject.parentElement.querySelectorAll('.spoiler_root.active').forEach(el => {
                        let elTrigger = el.querySelector('.spoiler_trigger');
                        console.log(elTrigger)
                        if(elTrigger){
                            elTrigger.dispatchEvent(new Event('click'))
                        }
                    })
                }
                let newHeight = elSpoiled.scrollHeight;
                elSpoiled.style.setProperty('height',`${newHeight}px`);
                elSubject.classList.add('active');
            }
        }
   }
   arPlus.forEach( el => el.addEventListener('click',handleSpoiler));
});